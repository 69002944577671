<template>
    <Page>
        <ViewTitle />
        <h2 class="display-none-heading">Übersicht</h2>
        <h3 class="mb-2 search-title">{{ labels.FormFilter }}</h3>
        <div class="vcs-search mb-4">
            <div class="cse-box">
                <label class="vgs-ac-label" for="fm_search_term">{{ labels.FormSearch }}</label>
                <b-input id="fm_search_term" :aria-label="labels.FormSearch" :placeholder="labels.FormSearch" type="text" v-model="searchTerm"></b-input>
                <div class="sbo-icon"><b-icon icon="search"></b-icon></div>
            </div>
        </div>

        <div class="container filter-word mb-2">
            <div class="row">
                <div v-for="item in this.get_letters" :key="'lc' + item.Letter" class="vcs-letter" :class="item.Style" @click="set_letter(item.Letter)">
                    <a href="#">{{ item.Letter.toUpperCase() }}</a>
                </div>
            </div>
        </div>

        <h3 class="mb-2 search-title">{{ labels.FormList }}</h3>
        <div v-for="typeItem in this.get_category_types" :key="'tc' + typeItem.UUID">
            <div class="vcs-type pt-2 pb-2 pl-3 pr-3">{{ get_type_translation('FormCategory',typeItem.CategoryTypeName,'label') }}</div>
            <div v-for="item in get_forms" :key="'fc' + item.UUID">
                <Toggle v-if="typeItem.CategoryTypeName == item.CategoryTypeName" mode="line" :id="'id_' + item.UUID" group="forms" :btnlabel="labels.EMailReminder" btnicon="reminder" btnlabelright css="table forms" btnhide>
                    <template v-slot:title>
                        <div class="container target-container" @click="goToOtherTarget(item.Url)">
                            <div class="row mb-2">
                                <div class="col-12">
                                    <a class="vgs-aria-link" :href="item.Url" :title="item.Name" :aria-label="item.Name" :aria-description="item.Description">{{ item.Name }}</a>
                                    <div class="vcs-form-name vgs-link-highlight"><span class="vgs-link-highlight">{{ item.Name }}</span></div>
                                    <div class="vcs-form-description">{{ item.Description }}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <!-- <template v-slot:content>
                      <div class="container">
                        <div class="row">
                          <div class="col">
                            <FormsEMailReminderForm :ID="'rf_' + item.UUID"></FormsEMailReminderForm>
                          </div>
                        </div>
                      </div>
                    </template> -->
                </Toggle>
            </div>
        </div>
    </Page>
</template>

<script>
    import Page from '@/structure/Page.vue'
    import Toggle from '@/structure/Toggle.vue'
    //import IconSet from '@/structure/IconSet.vue'
    //IconSet
    // import FormsEMailReminderForm from '@/forms/FormsEMailReminderForm.vue'
    import ViewTitle from '@/components/ViewTitle.vue'
    import axios from 'axios'

    import { vcm } from '@/mixins/vcm'
    import { vvm } from '@/mixins/vvm'

    export default {
        mixins: [vcm, vvm],
        name: 'Forms',
        components: {
            Page, ViewTitle, Toggle,
            // FormsEMailReminderForm
        },
        data() {
            return {
                listCollection: [],
                categoryTypeCollection: [],
                labels: {
                    EMailReminder: this.get_scope_translation('EMailReminder', 'label'),
                    FormList: this.get_scope_translation('FormList', 'label'),
                    FormFilter: this.get_scope_translation('FormFilter', 'label'),
                    SearchTerm: this.get_scope_translation('SearchTerm', 'label'),
                    PDF: this.get_scope_translation('PDF', 'label'),
                    Word: this.get_scope_translation('Word', 'label'),
                    FormSearch: this.get_scope_translation('FormSearch', 'label'),
                    Link: this.get_scope_translation('Link', 'label'),
                },
                searchTerm: '',
                searchInitial: '',
                searchLetters: 'abcdefghijklmnopqrstuvwxyz',
                searchSelectedLetter: '',
            };
        },
        //   created: function () {
        //     // Call //
        //     // this.$root.data_access.setIdentifier("list.forms");
        //     // this.$root.data_access.setReceiver("view", this);
        //     // this.$root.data_access.call("forms");
        //   },
        async created() {
            try {
                // add in url ${this.$root.get_user_uuid}/
                const Url = process.env.VUE_APP_BASE_URL + `/v1/forms/${this.$root.get_user_uuid}`;
                const response = await axios.get(Url)
                if (response.data.responseCode === '200' && response.data.response) {
                    this.listCollection = response;
                    var scopeThis = this
                    this.listCollection.data.response.forEach(item => {
                        var checkIndex = scopeThis.categoryTypeCollection.findIndex(x => x.CategoryTypeName == item.CategoryTypeName);
                        if (checkIndex <= -1) {
                            scopeThis.categoryTypeCollection.push({ UUID: item.UUID, CategoryTypeName: item.CategoryTypeName });
                        }
                    });
                }
            } catch (e) {
                console.log('Error : ' + e)
            }

        },
        computed: {
            get_letters: function () {
                var rv = []
                var letters = this.searchLetters.split('')
                for (var i = 0; i < letters.length; i++) {
                    var letter = letters[i]
                    let cssClass = this.searchSelectedLetter == letter ? 'active' : '';
                    rv.push({ Letter: letter, Style: cssClass })
                }
                return rv
            },
            get_category_types: function () {
                var rv = this.categoryTypeCollection
                rv.sort((a, b) => {
                    return a.CategoryTypeName - b.CategoryTypeName;
                })
                return rv
            },
            get_forms: function () {
                var rv = this.listCollection.data.response
                if (this.searchInitial != '') {
                    var scopeSearchInitial = this.searchInitial.toLowerCase()
                    rv = rv.filter(function (obj) {
                        return (obj.Name.substring(0, 1).toLowerCase() === scopeSearchInitial)
                    })
                }

                if (this.searchTerm != '') {
                    var scopeSearchTerm = this.searchTerm.toLowerCase()
                    rv = rv.filter(function (obj) {
                        return ((obj.Name.toLowerCase().indexOf(scopeSearchTerm) !== - 1) || obj.Description.toLowerCase().indexOf(scopeSearchTerm) !== - 1)
                    })
                }

                rv.sort((a, b) => {
                    return a.Name - b.Name;
                })

                return rv
            }
        },
        methods: {
            goToOtherTarget(targetPath) {
                window.open(targetPath, '_blank');
            },
            // receive(dataObject) {
            //   if (dataObject) {
            //     switch (dataObject.identifier) {
            //       case "list.forms": // Receive //
            //         this.listCollection = dataObject.data.response;
            //         Vue.vclDeploy('list.forms',this.listCollection)
            //         var scopeThis = this
            //         this.listCollection.forEach(function(item){
            //           var checkIndex = scopeThis.categoryTypeCollection.findIndex(x => x.CategoryTypeName == item.CategoryTypeName);
            //           if (checkIndex <= -1) {
            //             scopeThis.categoryTypeCollection.push({ UUID: item.UUID, CategoryTypeName: item.CategoryTypeName});
            //           }
            //         });


            //         /*
            //          "forms": [
            //     { "ID": 1, "Name": "Baubewilligungsansuchen", "Description": "Ansuchen zur Bewilligung", "Type": "Hochbau", "PDFUrl": "", "WordUrl": ""},
            //     { "ID": 2, "Name": "Bewilligung Bauvorhaben", "Description": "Ansuchen zur Bewilligung", "Type": "Hochbau", "PDFUrl": "", "WordUrl": ""},
            //     { "ID": 3, "Name": "Bewilligung Fällung Waldeigentümer", "Description": "Ansuchen zur Bewilligung", "Type": "Land und Forstwirtschaft", "PDFUrl": "", "WordUrl": ""},
            //     { "ID": 4, "Name": "Bewilligung Flugblattwerbung", "Description": "Ansuchen zur Bewilligung", "Type": "Sondergenehmigung", "PDFUrl": "", "WordUrl": ""},
            //     { "ID": 5, "Name": "Bewilligung - Fällung Servitutsberechtigte", "Description": "Ansuchen zur Bewilligung", "Type": "Land und Forstwirtschaft", "PDFUrl": "", "WordUrl": ""},
            //     { "ID": 6, "Name": "Befahren der Fußgängerzone (Bewilligung)", "Description": "Ansuchen zur Bewilligung", "Type": "Sondergenehmigung", "PDFUrl": "", "WordUrl": ""}
            // ],


            //         */

            //         break;
            //     }
            //   }
            // },
            set_letter(letter) {
                if (this.searchSelectedLetter == letter) {
                    this.searchSelectedLetter = ''
                    this.searchInitial = ''
                } else {
                    this.searchSelectedLetter = letter
                    this.searchInitial = letter.toLowerCase()
                }
            }
        }
    }

</script>

<style scoped lang="scss">
    @import "@/assets/_var.scss";

    .vcs-form-name {
        font-size: 0.938rem;
        line-height: 1.75rem;
        font-weight: 700;
        vertical-align: bottom;
    }

    .vcs-form-description {
        font-size: 0.938rem;
        line-height: 1.25rem;
    }

    h3 {
        font-size: 18px;
        line-height: 1.688rem;
        font-weight: 700;
    }

    .vcs-link {
        font-size: 0.9rem;
        line-height: 1.5rem;
    }

    .vcs-letter {
        float: left;
        width: 3.846153846153846%;
        text-align: center;
        cursor: pointer;
    }

    .vcs-letter a {
        text-decoration: none;
        font-size: 1rem;
        line-height: 1.25rem;
    }

    .vcs-letter:hover a {
        text-decoration: none;
    }

    .vcs-letter:hover a:hover {
        text-decoration: none;
    }

    .vcs-letter.active {
        background-color: $highlightInvertedBGColor;
        color: $highlightInvertedFontColor;
    }

    .vcs-letter.active a {
        color: $highlightInvertedFontColor;
    }

    .vcs-letter:hover {
        background-color: $highlightInvertedBGColor;
        color: $highlightInvertedFontColor;
    }

    .vcs-letter:hover a {
        color: $highlightInvertedFontColor;
    }

    .vcs-type {
        background-color: #ebf0f4;
    }

    .vcs-search {
        position: relative;
    }

    .vcs-search .cse-box {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 15rem;
    }

    .vcs-search .cse-box .form-control {
        padding-left: 2.5rem;
    }

    .vcs-search .cse-box .form-control:focus {
        outline: none;
        box-shadow: none;
        border-color: $highlightColor;
    }

    .vcs-search .cse-box .sbo-icon {
        position: absolute;
        bottom: 0.3rem;
        left: 0.75rem;
    }

    .target-container {
        cursor: pointer;
    }

    .display-none-heading {
        display: none;
    }

    @media (max-width:767px) {
        .vcs-search .cse-box[data-v-7f40ba36] {
            position: relative;
            width: 100%;
        }

        .filter-word {
            overflow-x: scroll;
        }

        .filter-word div {
            display: table;
        }

        .filter-word .vcs-letter {
            padding: 10px;
            display: table-cell;
            float: unset;
        }

        .search-title {
            // display: none;
        }

        .vcs-search .cse-box {
            width: 100%;
        }
    }
</style>

